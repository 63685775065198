$theme-color-orange3: #ff914d;
$theme-color-orange2: #ff914d9f;
$theme-color-orange1: #ff914d54;

$theme-color-blue3: #007bb6;
$theme-color-blue2: #007cb6a8;
$theme-color-blue1: #007cb652;
$theme-color-light-blue: #38b6ff;

$box-shadow: 8px 8px 12px rgba(8, 13, 56, 0.671);

$menu-box-shadow: 0px 0px 10px black;
$menu-border:2px white inset;
$menu-border-radius:12px;

$small-screen: 768px;




.link-icons{
    width: 90%;
    height: 80px;
    min-width: 200px;
    margin: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    border: $menu-border;
    border-radius: $menu-border-radius;
    box-shadow: $menu-box-shadow;
    background: $theme-color-orange3;
    *{
        cursor: pointer;
        height: 50px;
        width: 50px;
        box-shadow: 3px 3px 8px black;
        border-radius: 50%;
        border: 2px solid white;
        background: white;
        transition: .3s;

        &:hover{
            box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.514);
        }
        &.selected{
            box-shadow: 0px 0px 14px rgb(238, 238, 156);
            height: 40px;
            width: 40px;
            filter: grayscale(1.5);
        }
    }

}

.contact-me-content{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: column nowrap;
    margin-top: 2rem;
    margin: .25rem auto;
    width: 90%;
    height: 63%;
    border: $menu-border;
    border-radius: $menu-border-radius;
    box-shadow: $menu-box-shadow;
    background-color: $theme-color-blue3;
    color: white;

    a{
        &:link,&:visited,&:active{
            color: $theme-color-orange3;
            text-decoration: none;
        }
        margin: .25rem;
    }

    .copy-icon{
        position: relative;
        cursor: pointer;
        height: 50px;
        width: 50px;
        margin: auto;

        &::after{
            user-select: none;
            cursor: default;
            opacity: 0;
        }

        &.clicked::after{
            user-select: none;
            animation: fade-in-out 1.5s ease-in-out 0s 1 forwards;
            position: absolute;
            bottom: -1rem;
            margin: auto;
            background-color: rgba(0, 0, 0, 0.575);
            padding: .35rem;
            border-radius: 8px;
            content: "Copied to Clipboard";
        }

        img{
            height: 50px;
            
        }
    }
    
}

@media (max-width: $small-screen) {
    .circle-footer{
        height: 1rem;
        width: 1rem;
    }
}
@media (min-width: $small-screen){
    .circle-footer{
    height: 1.3rem;
    width: 1.3rem;
    }
}

.contact-footer{
    display: flex;
    position: absolute;
    bottom: 0;
    height: 2rem;
    width: 100%;

}


.contact-me-info{
    display: flex;
    flex-flow: column nowrap;
    opacity: 0;
    font-size: 1.2rem;
    @media (max-width:$small-screen){
        font-size: .9rem;
    }
    animation: fade-in-contact 1s ease-in .2s 1 forwards;

    #email{
        background-color: black;
        padding: .25rem;
        margin-bottom: 1rem;
    }
    span{
        margin-bottom: .3rem;
    }
}

@keyframes fade-in-contact{
    0%{opacity: 0;}
    100%{opacity: 1;}
}

@keyframes transition-left {
    0%{opacity: 1; left: 0;}
    100%{ opacity: 0;left: -100%; }
}
@keyframes transition-from-right {
    0%{ opacity: 0; left: 200%;}
    100%{ opacity: 1;left: 0;}
}
@keyframes transition-right {
    0%{opacity: 1; left: 0;}
    100%{opacity: 0; left: 200%;}
}
@keyframes transition-from-left {
    0%{opacity: 0; left: -100%;}
    100%{opacity: 1; left: 0;}
}
@keyframes fade-in-out {
    0%{opacity: 0;}
    20%{opacity: 1;}
    80%{opacity: 1;}
    100%{opacity: 0;}
}