$theme-color-orange3: #ff914d;
$theme-color-blue3: #007bb6;

.intro-header{
    color: $theme-color-blue3;
}
.intro-map{
    width: 80%;
    max-width: 450px;
    height: 100%;
    justify-content: center;
    margin: auto;
    color: white;

    
    tbody {
        user-select: none;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;

        
        tr{
            display: inline-flex;
            gap: 5.1rem;
            align-items: center;
            padding: 1.2rem .7rem;
            margin-bottom: 10px;
            border-radius: 5px;
            transition: .2s;
            text-align: left;
            
            &:hover{
                cursor: pointer;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.568);
                background-color: $theme-color-blue3;
                transition: .3s;
                gap: 2.7rem;
                
                td.link-name{
                    opacity: 0;
                }
                td span.icon{
                    img{
                        // filter: invert(55%) sepia(84%) saturate(372%) hue-rotate(337deg) brightness(100%) contrast(102%);
                        filter: invert(0%) sepia(6%) saturate(25%) hue-rotate(224deg) brightness(107%) contrast(107%);
                        left: -4.1rem;
                        width: 2.9rem;
                        transition: .3s;
                    }
                }
                td:last-child{
                    font-weight: bold;
                }
            }
        }
        span.icon{
            img{
                position: absolute;
                left: -2.7rem;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 2rem;
                filter: grayscale(100%) invert(100%) contrast(1.25);
                transition: .3s;

            }
        }
        td.link-name{
            width: 2rem;
            font-weight: bold;
            text-align: right;
            color: $theme-color-orange3;
        }
        td:last-child{
            position: relative;
            font-size: .9rem;
        }
    }
}