$theme-color-orange4: #db8047;
$theme-color-orange3: #ff914d;
$theme-color-orange2: #ff914d9f;
$theme-color-orange1: #ff914d54;
@import url('https://fonts.googleapis.com/css2?family=Jost&family=Teko&display=swap');
$theme-color-blue3: #007bb6;
$theme-color-blue2: #007cb6a8;
$theme-color-blue1: #007cb652;
$theme-color-light-blue: #38b6ff;

$title-color: black;
$title-shadow: 3px 3px 4px rgba(0, 0, 0, 0.493);

$small-screen: 768px;

.project-wrapper.active{
    display: block;
}
.project-wrapper{
    display: none;
}

.project-wrapper{
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .project-page-header{
        padding: .5rem ;
        margin-bottom: 1rem;
        text-align: center;

        box-shadow:
         inset 0 -.4rem 4px 2px rgba(48, 48, 48, 0.384),
         inset 0 .5rem 12px 4px rgba(50, 146, 255, 0.596),
         inset 0 .2rem 4px rgba(0, 0, 0, 0.575),
        ;

        border-radius: 20px;

        background-color: $theme-color-blue3;

        .project-page-next:hover, .project-page-prev:hover {
            cursor: pointer;
            box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.233),
                        inset -2px -2px 4px rgba(0, 0, 0, 0.233);
            background-color: rgba(105, 105, 105, 0.123);
            
        }
        .project-page-next:active, .project-page-prev:active {
            box-shadow: inset 2px 3px 6px rgba(0, 0, 0, 0.548);
            background-color: rgba(65, 64, 64, 0.397);
            
        }
        
        .project-page-category{
            color: white;
            font-size: 1.4rem;
            font-weight: bold;
            font-variant: small-caps;
            text-shadow: 0 0 4px rgba(12, 12, 12, 0.616);
            margin: 0;
            padding: 0 0 0 .5rem;

            &::before{
                position: absolute;
                width: 80%;
                height: 26px;
                content: " ";
            }

            &::before:hover{
                box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.473);
            }
        }

        .project-page-prev, .project-page-next{
            transition: .2s;
            position: absolute;
            top: 0px;
            display: flex;
            justify-content: center;
            align-content: center;
            height: 27px;
            padding: .5rem;
            text-align: center;
            img{
                height: 25px;
                width: 25px;
            }
            
        }
        
        .project-page-prev{
            left: 0px;
            border-radius: 20px 0px 0px 20px;
            padding-right: 2rem;
        }
        .project-page-next{
            border-radius: 0px 20px 20px 0;
            right: 0px;
            padding-left: 2rem;
        }     
    }
    
    .project-content{
        height: 100%;
        padding: 0 1rem;
        
        .project-name{
            color: rgb(255, 255, 255);
            stroke: black 2px;
            text-shadow: 
                -1px -1px 1px $theme-color-orange4,
                -1px  1px 1px $theme-color-orange4,
                 1px -1px 1px $theme-color-orange4,
                 1px  1px 1px $theme-color-orange4,
                 0px  0px 2px black,
                 0px  0px 2px black,
                 0px  0px 2px black,
                 0px  0px 3px black,
                 0px  0px 3px black,
                 0px  0px 6px rgba(0, 0, 0, 0.363);
            
            font-family: 'Jost', sans-serif;
            font-weight: bold;
            font-size: 1.8rem;
            text-align: center;
            padding: .75rem;
            letter-spacing: 2px;
            border-radius: 15px;
        }
        .project-summary{
            color: $theme-color-blue3;
            font-weight: bold;
            font-size: 1rem;
            text-align: center;
            position: relative;
            top: -1rem;
        }

        .project-description{
            padding: .5rem ;
            text-align: left;
            color: black;
            border-radius: 10px;
            background-color: rgba(255, 255, 255, 0.918);
            box-shadow: 2px 2px 10px rgba(214, 214, 214, 0.712), inset rgba(32, 32, 32, 0.658) 0px 0px 7px;
            
            p{
                text-indent: 1rem;
                padding: 0 2.75rem;
            }
        }
        
    }
    
    
}

.project-links{
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    flex-flow: row;
    height: 2.5rem;
    margin-top: .25rem;
    margin-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

}

section.project-tools{
    padding: .5rem;
    display: flex;
    overflow-x: auto;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 750px;
    border-radius: 10px;

    span{
        font-size: .8rem;
        padding: .5rem;
        background-color: $theme-color-orange3;
        color: white;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.856), white 0 0 2px;
        box-shadow: inset -1px 2px 4px white;
        margin: .25rem;
        border-radius: 12px;
        border: 2px solid rgba(0, 0, 0, 0.226);
    }
    
}

footer.project-footer{
    opacity: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 0;

    width: 100%;
    height: 131px;

    background-color: rgba(0, 0, 0, 0.425);
    background-image: 
        linear-gradient(rgba(41, 40, 40, 0.733), rgba(27, 5, 5, 0.603)),
        url("../images/textures/window-texture.png");
    box-shadow: inset 0 0 10px 4px rgba(0, 0, 0, 0.678);

    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    gap: 0px;

    z-index: 30;
    border: 1px inset black;
    transition: .3s ease-out, opacity .8s .3s;

    &.fade-in{
        opacity: 1;
    }

    .project-footer-img-wrapper{
        width: 100%;
        overflow-x: auto;
        display: flex;
        justify-content: center;
    }
    img.project-image{
        height: 6rem;
        margin: 1rem;
        box-shadow: 0 2px 6px 2px rgb(24, 24, 24);
        transition: transform .2s;
        &:hover{
            cursor: zoom-in;
            transform: scale(1.1);
        }

        &.active{
            transform: scale(1.1);
            border: white 4px solid;
            pointer-events: none;

        }
    }

    &.hide{
        bottom: -131px;

        .project-footer-toggle:hover{
            cursor: pointer;
            padding-bottom: .5rem;
            height: 3rem;
            top: -3.5rem;
            box-shadow: inset 0px 0px 3px 2px rgba(3, 3, 3, 0.308) , inset 0px 1px 4px 2px rgba(0, 0, 0, 0.459), inset -2px 1px 10px 3px $theme-color-light-blue, 2px -4px 8px rgba(0, 0, 0, 0.479);
        }
    }
}

footer.project-footer.full-screen{
    bottom: 0%;
    height: 100%;
    
    .project-footer-img-wrapper{
        padding-top: 1.75rem;
    }

    i.exit-icon{
        display: flex;
        opacity: 1;
    } 
}

.project-active-img{
    margin: auto;
    img{
        max-height: 500px;
        max-width: 900px;
    }
}

i.exit-icon{
    opacity: 0;
    display: none;
    img:hover{
        cursor: pointer;
        border: 2px solid black;
    }
    img{
        &:focus-visible, &:focus {
            outline: 2px solid white; 
            border: 2px solid black;    
        }
        margin: auto;
        max-width: 25px;
        max-height: 25px;
        border-radius: 100%;
        border: 2px solid rgba(0, 0, 0, 0);
        transition: border .2s, opacity .1s .4s;
    }
}

.project-footer-toggle{

    font-family: 'Jost', cursive;
    font-size: .8rem;
    text-shadow: 0 0 4px black;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: left;
    align-items: center;
    position: absolute;
    background-color: $theme-color-blue3;
    box-shadow: inset 0px 0px 3px 2px rgba(3, 3, 3, 0.308) , inset 0px 1px 4px 2px rgba(0, 0, 0, 0.459), inset -2px 1px 10px 3px $theme-color-light-blue;
    height: 3rem;
    width: 15rem;
    top: -3rem;
    transition: .2s;
    border-radius: 10px 10px 0 0;

    &::before{
        position: absolute;
        height: 3.5rem;
        width: 15rem;
        content: "";
    }
    
    &:hover{
        cursor: pointer;
        height: 2.3rem;
        top: -2.3rem;
        box-shadow: inset 0px 0px 3px 2px rgba(3, 3, 3, 0.308) , inset 0px 1px 4px 2px rgba(0, 0, 0, 0.459), inset -2px 1px 10px 3px $theme-color-light-blue, 2px -4px 8px rgba(0, 0, 0, 0.479);
    }
}

i.num-circle{
    text-shadow: none;
    color: black;
    text-decoration: none;
    font-style: normal;
    position: absolute;
    right: 1.5rem;
    border-radius: 100%;
    background-color: white;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.project-modal{
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior-y: contain;
    scroll-snap-type: y proximity;
    .project-carousel-wrapper{
        &::after{
            position: relative;
            content: "";
            height: 500px;
            width: 500px;
            display: block;
        }
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 5rem;
        
        img{
            scroll-snap-align: center;
            max-height: 500px;
            margin: auto;
        }
    }

}

@keyframes fade-in{
    0%{
    opacity: 0;
    box-shadow: none;
    }
    80%{opacity: 1;}
    100%{
    opacity: 1;
    box-shadow: 3px -3px 8px rgba(0, 0, 0, 0.356);
    }
}
@keyframes fade-out{
    0%{
    opacity: 1;
    box-shadow: 3px -3px 8px rgba(0, 0, 0, 0.356);
    }
    80%{box-shadow: none;}
    100%{
    opacity: 0;
    box-shadow: none;

    }
}
