$theme-color-orange3: #ff914d;
$theme-color-blue3: #007bb6;

@keyframes fade-in-welcome{
    0%{opacity: 0;};
    100%{opacity: 1;};
}

@keyframes door-glow{
    0%{box-shadow: -5px -5px 10px $theme-color-orange3, 5px 5px 10px $theme-color-blue3;}
    25%{box-shadow: -40px -40px 80px $theme-color-orange3, 40px 40px 80px $theme-color-blue3;}
    50%{box-shadow: -80px -20px 90px $theme-color-orange3, 80px 20px 90px $theme-color-blue3;}
    70%{box-shadow: -10px 20px 80px $theme-color-orange3, 10px -20px 80px $theme-color-blue3;}
    100%{box-shadow: -30px 0px 80px $theme-color-orange3, 30px 0px 80px $theme-color-blue3;}
}

.intro-door-container{
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: black;
    
    img{
        cursor: pointer;
        margin: auto;
        box-shadow: -5px -5px 10px $theme-color-orange3, 5px 5px 10px $theme-color-blue3;
    }
    .door-text{
        width: 100%;
        position: absolute;
        color: white;
        font-size: 2rem;
        text-shadow: white 0px 0px 10px;
        bottom: 3rem;
        span {
            opacity: 0;            
        }
        
    }
    &.selected{
        background-color: rgba(0, 0, 0, 0);
        transition: background-color 3.2s 4s;
        
        img{
            animation: door-glow 6s ease-in-out alternate infinite;
        }
        img,.door-text{
            filter: blur(5px);
            opacity: 0;
            transition:  opacity 1.8s 3.7s, filter 1.8s 3.8s;
        }
        .door-text{
            span{
                animation: fade-in-welcome .8s ease-in forwards;
                @for $span from 1 through 20{
                    &:nth-child(#{$span}){
                    animation-delay: 400ms * $span;
                    }
                }
            }
        }
    }
}
