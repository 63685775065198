$theme-color-orange3: #ff914d;
$theme-color-orange2: #ff914d9f;
$theme-color-orange1: #ff914d54;

$theme-color-blue3: #007bb6;
$theme-color-blue2: #007cb6a8;
$theme-color-blue1: #007cb652;
$theme-color-light-blue: #38b6ff;
$box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.548);

.App {
  text-align: center;
  background-size: auto;
  height: 100vh;
  width: 100vw;
  transition: background-image 4s;
  -moz-transition: background-image 4s;
  -webkit-transition: background-image 4s;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility:    hidden;
  -ms-backface-visibility:     hidden;

  .background-image{
    z-index: -10;
    height: inherit;
    width: inherit;
    position: fixed;
    
    *{
      background-attachment: fixed;
      background-size: cover;
      background-repeat: no-repeat;
      position: fixed;
      height: inherit;
      width: inherit;
      transition: opacity 4s;
      -moz-transition: opacity 4s;
      -webkit-transition: opacity 4s;
      
    }
    .home{
      opacity: 0;
      background-image: url('./images/textures/background-night1.png');
    }
    .projects{
      opacity: 0;
      background-image: url('./images/textures/background-noon.png');
    }
    .skills{
      opacity: 0;
      background-image: url('./images/textures/background-evening2.png');
    }
    .about{
      opacity: 0;
      background-image: url('./images/textures/background-morning.png');
    }
    .credits{
      opacity: 0;
      background-image: url('./images/textures/background-night2.png');
    }
    .resume{
      opacity: 0;
      background-image: url('./images/textures/background-morning2.png');
    }
    .contact{
      opacity: 0;
      background-image: url('./images/textures/background-evening.png');
    }
    .home.show,.projects.show,.skills.show,.about.show,.credits.show,.resume.show, .contact.show{
      opacity: 1;
      // transition: 4s;
    }
    
    
  }
  
}

.tooltip{
  z-index: 1000;
  opacity: 1;
  position: fixed;
  display: block;
    img{
      border: 2px solid;
      border-color: 
        $theme-color-blue3
        $theme-color-light-blue
        $theme-color-light-blue
        $theme-color-blue3;
      box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.527);
      height: 300px;
    }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.main-wrapper {
  z-index: 10;
  height: 100%;
  overflow: hidden;
}

*{
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

}

