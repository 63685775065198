@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

$theme-color-orange: #ff914d;
$theme-color-orange_dark: #b36739;
$theme-color-blue: #007bb6;
$theme-color-light-blue: #38b6ff;
$theme-color-blue80: #007cb6b6;
$theme-color-light-blue80: #38b6ffbb;
$theme-color-white: rgba(255, 255, 255, 0.781);
$box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.548);

body {
  margin: 0;
  font-family: 'Lato',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  overflow: hidden;
}

body, .app, html{
  height: 100vh;
  @media (max-width: 800px){
    height: 100%;

  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
