$theme-color-orange: #ff914d;
$theme-color-blue: #007bb6;
$small-screen: 900px;

@media (min-width: $small-screen){
  .navbar-button,.navbar-mobile{
      display: none;
      opacity: 0;
  }   
}

.navbar-button{
  cursor: pointer;
  opacity: 1;
  position: fixed;
  top: .25rem;
  left: 50%;
  margin-left: -25px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
  font-size: 40px;
  color: rgb(250, 250, 250);
  line-height: 42px;
  z-index: 80;
  transition: .8s;

  &.hide{
    top: -2rem;
    opacity: 0;
    transition: .2s;
  }
}

.navbar-mobile-wrapper{
  opacity: 1;
  user-select: none;
  visibility: visible;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.801);
  transition: opacity .9s, background-color 1s;
  overflow: hidden;

  @keyframes navbar-mobile-hide{
    0%{opacity: 1; visibility: visible;}
    95%{opacity: 0; visibility: visible;}
    100%{opacity: 0; visibility: hidden;}
  }
  &.disable{
    animation: navbar-mobile-hide 1.2s forwards;
    // display: none;
    // opacity: 0;
    // transition-duration: 1s;
  }
  @keyframes navbar-button-fade{
    0%{ opacity: 0;}
    100%{ opacity: 1;}
  }
  .navbar-exit-button{
    cursor: pointer;
    position: absolute;
    opacity: 0;
    top: 2rem;
    left: 83%;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: rgb(160, 160, 160);
    color: rgb(31, 31, 31);
    &.show-menu{
      animation: navbar-button-fade 1s 1.2s forwards;
    }
    &:hover{background-color: rgba(255, 255, 255, 0.295); transition: .3s;}
    &::after{
      position: relative;
      top: 25%;
      content: url('../images/icons/close.svg');
    }
  }

@keyframes navbar-menu-open{
  0%{transform: scale(.2) rotate(60deg);opacity: 0; top: 0;}
  100%{transform: scale(1) rotate(0deg);opacity: 1; top: 50%;}
}
@keyframes navbar-menu-close{
  0%{transform: rotate(0deg) scale(1); opacity: 1; top: 50%;}
  100%{transform: rotate(-60deg) scale(.2); opacity: 0; top: -10%;}
}
  .navbar-menu{
    display: none;
    position: relative;
    border-radius: 50%;
    height: 250px;
    width: 250px;
    left: 50%;
    margin-left: -125px;
    margin-top: -125px;
    div{
      top: 50%;
      left: 50%;
      transition: 1s;
    }
    &.show-menu{
      display: block;
      animation: navbar-menu-open 1s ease-in-out forwards;
      div{
        transition: .6s ;
      }
      &.exit{
        animation: navbar-menu-close 1s ease-in-out forwards;
      }
      div:nth-child(1){
        top: 0;
        left: 25%;
      }
      div:nth-child(2){
        top: 50%;
        left: 0;
      }
      div:nth-child(3){
        top: 100%;
        left: 25%;
      }
      div:nth-child(4){
        top: 0;
        left: 75%;
      }
      div:nth-child(5){
        top: 50%;
        left: 100%;
      }
      div:nth-child(6){
        top: 100%;
        left: 75%;
      }
      div:nth-child(1):hover,div:nth-child(2):hover,div:nth-child(3):hover,div:nth-child(4):hover,div:nth-child(5):hover,div:nth-child(6):hover{
        left: 50%;
        top: 50%;
        transition-delay: .05s;
        transition-property: left, top;
      }      
    }
      span{
        pointer-events: none;
        color: white;
        background-color: black;
        border-radius: 50%;
        position: fixed;
        top: 47%;
        left: 50%;
        height: 100px;
        width: 100px;
        margin-top: -50px;
        margin-left: -50px;
        padding: 0;
        line-height: 100px;
        opacity: 0;
      }    
  
    .navbar-link{
      cursor: pointer;
      display: flex;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      position: absolute;
      background-color: white;
      margin-top: -30px;
      margin-left: -30px;
      

      &.left{
        background-color: $theme-color-orange;
      }
      &.right{
        background-color: $theme-color-blue;
      }
      &.navbar-link-disabled{
        user-select: none;
        pointer-events: none;
        opacity: .3;
        box-shadow: 0px 0px 10px rgb(65, 65, 65);
        
      }

      
      img{
        margin: auto;
        height: 50px;
        width: 50px;
      }
      &:hover{
        background-color: rgb(255, 255, 255);
        box-shadow: white 0px 0px 20px;
        
        
      }
      &:hover>span{
        pointer-events: all;
        opacity: 1;
        top: 50%;
        left: 50%;
        box-shadow: $theme-color-blue 5px 0px 18px, $theme-color-orange -5px 0px 18px;
        text-shadow: 0px 0px 12px white;
        transition: .4s;
        transition-delay: .1s;

        &:hover{
            color: rgba(0, 0, 0, 0.384);
            top: 100%;
            height: 0;
            width: 0;
            margin-left: 0;
            margin-top: 0;
            font-size: 0;
            opacity: 0;
            transition: .8s;
        }
        
      }
    }
  }
  


}