$theme-color-orange3: #ff914d;
$theme-color-orange2: #ff914d9f;
$theme-color-orange1: #ff914d54;

$theme-color-blue3: #007bb6;
$theme-color-blue2: #007cb6a8;
$theme-color-blue1: #007cb652;
$theme-color-light-blue: #38b6ff;
$box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.548);

$small-screen: 768px;

@keyframes circle-transition-left {
    from {
        opacity: 0;
        left: -4.2rem;
    }
    to{
        opacity: 1;
        left: 0;
    }
}
@keyframes circle-transition-left-out {
    from {
        opacity: 1;
        left: 0;
    }
    to{
        opacity: 0;
        left: -4.2rem;
    }
}

@keyframes circle-transition-right {
    from {
        opacity: 0;
        right: -4.2rem;
    }
    to{
        opacity: 1;
        right: 0;
    }
}
@keyframes circle-transition-right-out {
    from {
        opacity: 1;
        right: 0;
    }
    to{
        opacity: 0;
        right: -4.2rem;
    }
}
@keyframes move-out {
    from {
        top: 0;
    }
    to {
        top: -100%;
    }
}

@keyframes move-in {
    from {
        top: 100%;
    }

    to {
        top: 0;
    }
}

.content-tab-dropdown{
    position: absolute;
    right: 0;
    top: -4rem;
    color: $theme-color-blue3;
    font-weight: bold;
    text-shadow: $theme-color-blue1 0px 0px 8px, rgb(255, 255, 255) 0px 0px 4px;
    width: 120px;
    height: 2rem;
    text-align: center;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    font-family: inherit;
}

.section-container{
    display: flex;
    position: relative;
    top: 100%;
    background-color: white;
    width: 85%;
    height: 65%;
    max-width: 600px;
    margin: 4.5rem auto;
    box-shadow: $box-shadow;
    border: 18px solid;
    outline: 2px black solid;
    border-color: 
        $theme-color-blue3
        $theme-color-light-blue
        $theme-color-light-blue
        $theme-color-blue3;
    transition: 1s;
    animation: move-in 1s ease-out 1 normal forwards;

    &.content-projects{
        height: 90%;
        background: none;
        border: none;
        outline: none;
        box-shadow: none;
        .section-container-wrapper{
            height: 100%;
            border: none;
            box-shadow: none;
        }
        
    }

    &.about{
        background-color: rgba(0, 0, 0, 0.493);
        * > h2{
            color: white;
        }
        .section-container-wrapper{
            box-shadow: none;
        }
    }

    &.move-out{
        animation: move-out .7s ease-in 1 normal forwards;

        .content-button{
            opacity: 0;
            transition: .4s;
        }
    }
    @media (max-width: $small-screen){

        margin: 4rem auto;
        // max-height: 85vh;
        .content-button{
            position: fixed;
            bottom: -3.5rem;
            font-size: .8rem;
        }
    }
    @media (min-width: $small-screen){
        .content-button{
            position: absolute;
            bottom: -5rem;
        }
    }
    @media (max-height: 800px){
        .content-button{
            margin: 0 11%;
            position: fixed;
            bottom: 2.1rem;
        }
    }
    i{
        color: rgb(165, 165, 165);
    }
    .content-button{
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.664);
        border: 2px solid black;
        height: 2rem;
            width: 33%;
            max-width: 16rem;
            
        &.left:hover, &.right:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.836);
        }

        &.left.disable, &.right.disable{
            opacity: .3;
            pointer-events: none;
            cursor: not-allowed;
            background-color: gray;
            transition: background-color .6s, opacity .5s;
        }
       
        &.left{
            left: .5rem;
            z-index: 50;
            
            background-color: $theme-color-orange3;
            transition: background-color .6s;
            border-radius: 20px 10px 10px 20px;

        }
        &.right{
            right: .5rem;
            z-index: 50;
            background-color: $theme-color-blue3;
            transition: background-color .6s;
            border-radius: 10px 20px 20px 10px;
        }
    }

    .section-container-wrapper{
        display: flex;
        flex-flow: column nowrap;
        height: auto;
        width: 100%;
        border: 2px black solid;
        box-shadow: 0px 0px 10px gray inset;
        overflow-x: hidden;
        
        .section-header{
            display: flex;
            position: relative;
            opacity: 1;
            flex-flow: row nowrap;
            justify-content: center;
            align-content: center;
            height: 5rem;
            font-size: 2.5rem;

            h2{
                font-size: 1.8rem;
                padding: 0;
                margin: 0;
                margin-block-start: 0;
                margin-block-end: 0;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
            }

            &.transition{
                h2{opacity: 0;
                    transition: .6s;
                }
            }

            @media (max-width: $small-screen) {
                .circle{
                    height: 1rem;
                    width: 1rem;
                }
            }
            @media (min-width: $small-screen){
                .circle{
                height: 1.3rem;
                width: 1.3rem;
                }
            }
            
            .circle{
                opacity: 0;
                position: relative;
                border-radius: 50%;
                margin: auto;
                

                &.orange{
                           
                    &.one{
                        animation: circle-transition-left .3s ease-out 1.6s 1 forwards;
                        -webkit-animation: circle-transition-left .3s ease-out 1.6s 1 forwards;
                        -moz-animation: circle-transition-left .3s ease-out 1.6s 1 forwards;
                        background-color: $theme-color-orange1;
                        &.transition{
                            animation: circle-transition-left-out .3s ease-out 1 forwards;
                            -webkit-animation: circle-transition-left-out .3s ease-out 1 forwards;
                            -moz-animation: circle-transition-left-out .3s ease-out 1 forwards;
                        }
                    }
                    &.two{
                        animation: circle-transition-left .4s ease-out 1.4s 1 forwards;
                        -webkit-animation: circle-transition-left .4s ease-out 1.4s 1 forwards;
                        -moz-animation: circle-transition-left .4s ease-out 1.4s 1 forwards;
                        background-color: $theme-color-orange2;
                        &.transition{
                            opacity: 1;
                            animation: circle-transition-left-out .3s ease-out .2s 1 forwards;
                            -webkit-animation: circle-transition-left-out .3s ease-out .2s 1 forwards;
                            -moz-animation: circle-transition-left-out .3s ease-out .2s 1 forwards;
                        }
                    }
                    &.three{
                        background-color: $theme-color-orange3;
                        animation: circle-transition-left .5s ease-out 1.1s 1 forwards;
                        &.transition{
                            opacity: 1;
                            animation: circle-transition-left-out .3s ease-out .4s 1 forwards;
                            -webkit-animation: circle-transition-left-out .3s ease-out .4s 1 forwards;
                            -moz-animation: circle-transition-left-out .3s ease-out .4s 1 forwards;
                        }
                    }
                }
                &.blue{
                    
                    &.one{
                        animation: circle-transition-right .3s ease-out 1.6s 1 forwards;
                        background-color: $theme-color-blue1;
                        &.transition{
                            animation: circle-transition-right-out .3s ease-out 1 forwards;
                        }
                    }
                    &.two{
                        animation: circle-transition-right .3s ease-out 1.4s 1 forwards;
                        background-color: $theme-color-blue2;
                        &.transition{
                            opacity: 1;
                            animation: circle-transition-right-out .3s ease-out .2s 1 forwards;
                        }
                    }
                    &.three{
                        animation: circle-transition-right .3s ease-out 1.1s 1 forwards;
                        background-color: $theme-color-blue3;
                        &.transition{
                            opacity: 1;
                            animation: circle-transition-right-out .3s ease-out .4s 1 forwards;
                        }
                    }
                }
            }
            h2{
                opacity: 1;
                font-size: 1.6rem;
                text-shadow: 4px 4px 4px rgba(156, 156, 156, 0.377);
                margin: auto;
                transition: .6s ease-out .6s;
            }
            hr{
                background-color: black;
                position: absolute;
                bottom: -1.5rem;
                left: 0;
                right: 0;
                height: .1rem;
                width: 95%;
            }
        }
        
        .content-container{
            display: flex;
            justify-content: center;
            align-content: center;
            height: 100%;
            overflow: hidden;


            .content-container-wrapper{
                
                position: relative;
                overflow-y: auto;
                overflow-x: hidden;
                height: 95%;
                width: 95%;
                margin: 0 1rem 1rem 1rem;
                padding-top: 1rem;

                &.no-scroll{
                    overflow-y: hidden;
                }
                &.transition-left{
                    animation: transition-left .4s ease-in 1 forwards;
                }
                &.transition-from-right{
                    animation: transition-from-right .4s ease-out 1 forwards;
                }
                &.transition-right{
                    animation: transition-right .4s 1 ease-in forwards;
                }
                &.transition-from-left{
                    animation: transition-from-left .4s 1 ease-out forwards;
                }

                &.spread-even{
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: space-around;
                }

            }


            

        }
    }

    footer{
        user-select: none;
        margin: .25rem;
        background: none;
        span{font-size: 1.3rem;}
        .current{
            color: $theme-color-light-blue;
        }
        
        }
    }


@keyframes transition-left {
    0%{opacity: 1; left: 0;}
    100%{ opacity: 0;left: -100%; }
}
@keyframes transition-from-right {
    0%{ opacity: 0; left: 200%;}
    100%{ opacity: 1;left: 0;}
}
@keyframes transition-right {
    0%{opacity: 1; left: 0;}
    100%{opacity: 0; left: 200%;}
}
@keyframes transition-from-left {
    0%{opacity: 0; left: -100%;}
    100%{opacity: 1; left: 0;}
}