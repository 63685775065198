@import url('https://fonts.googleapis.com/css?family=Muli|PT+Sans|Poppins|VT323&display=swap');
$card-unselected: #000000b9;
$theme-color-orange2: #ff914d;
$tool-theme: #000000b4;

$card-back-color: #007cb6b7;
$theme-color-blue2: #007cb6a8;
$theme-color-blue1: #007cb652;
$theme-color-light-blue: #38b6ff;

$font-family1: 'PT Sans','Courier New', Courier, monospace;
$font-family2: 'Muli','Courier New', Courier, monospace;

@keyframes move-in {
    from {
        top: 100%;
    }

    to {
        top: 0;
    }
}
@keyframes move-out {
    from {
        top: 0;
    }
    to {
        top: -130%;
    }
}
.skill-cards-container{
    pointer-events: none;
    position: fixed;
    height: 100%;
    width: 400px;
    min-width: 400px;
    left: 50%;
    margin-left: -200px;   
    perspective: 800px;
    animation: move-in 1.3s ease-out 1 normal forwards;

    &.move-out{
        animation: move-out .7s ease-in 1 normal forwards;

    }

    .skill-card{
        pointer-events: visible;
        cursor: pointer;
        position: absolute;
        height: 400px;
        width: 300px;
        transform-style: preserve-3d;
        bottom: -10%;
        transition: .7s;
        -moz-backface-visibility: hidden;
        
        &.unselected{
            height: 200px;
            width: 150px;
            margin-left: -75px;
            .title{
                position: relative;
                color: white;
                background-color: black;
                bottom: -1.7rem;
                margin-right: -5px;
                margin-bottom: -8px;
                z-index: 10;
            }           
            .face{
                &.front,&.back{
                    box-shadow: 10px -10px 15px rgba(0, 0, 0, 0.582);
                    background-color: $card-unselected;  
                    transition: background-color .4s .5s;                  
                }
                *{
                    opacity: 0;
                    transform: scale(.7);
                    transition: .4s;
                }}
            
            &.experience{
                left: 20%;
                transform: rotate(-10deg);
                transition: .5s;
                &:hover{
                    transform: rotate(0deg);
                }
            }
            &.design{
                left: 50%;
                transform: rotate(5deg);
                transition: .5s;
                &:hover{
                    transform: rotate(0deg);
                }
            }
            &.fortitude{
                left: 80%;
                transform: rotate(2deg);
                transition: .5s;
                &:hover{
                    transform: rotate(0deg);
                }
            }
        
        }
        
        &:hover{
            margin-bottom: 2rem;
        }

        &.selected{
        bottom: 60%;
        left: 50%;
        margin-bottom: -200px;
        margin-left: -147px;
        transition: .8s;

        .title{
            display: none;
        }

            &.flipped{
                transform: rotateY(180deg);
                transition: 1.2s;
               
            }

            header{
            color: white;
            background-color: black;
            font-weight: bold;
            padding: .25rem 0;
            }
        }

        
        .face{
            font-family: $font-family1;
            font-size: 1.5rem;
            border: rgb(0, 0, 0) 4px solid;
            border-bottom: 1rem solid black;
            border-radius: 10px;
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            *{
                opacity: 1;
                transition: opacity .6s .4s;
            }
            &.front{
                background-color: rgba(0, 0, 0, 0.452);
                
            }
            
            &.back{
                background-color: $card-back-color;
                transform: rotateY(180deg);
            }

            .skill-card__content{
                height: 100%;
                color: black;
                display: flex;
                flex-flow: column nowrap;
                div{
                    margin-top: .5rem;
                    color: white;
                    font-size: .9rem;
                    font-family: $font-family2;
                }
                span{
                    color: white;
                    font-size: 1.1rem;
                    line-height: 90%;
                    margin: auto;
                    width: 90%;
                    height: 1rem;
                    padding: 3px;
                    border-radius: 5px;
                    background-color: $tool-theme;
                    
                }
                span:hover{
                    background-color: $theme-color-orange2;
                    color: black;
                }
            }
        }


        header{
            font-size: 1.3rem;
        }
    }
}